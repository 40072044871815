import { Fragment, useEffect, useState } from 'react'
import { AppBar, Toolbar, Box, Grid } from '@mui/material'
//import AccountCircleIcon from '@mui/icons-material/AccountCircle'
//import MenuIcon from '@mui/icons-material/Menu'
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
//import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useCookies } from 'react-cookie'
import Link from 'next/link'
//import Image from 'next/image'
//import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import IconFull from './IconFull'
//import PriceChangeIcon from '@mui/icons-material/PriceChange';
//import * as gtag from "../../lib/gtag";
//import dynamic from 'next/dynamic'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Categorias from '../landing/Categorias'

import Buscador from '../landing/Buscador'

//const Buscador = dynamic(() => import('../landing/Buscador'))
//const Categorias = dynamic(() => import('../landing/Categorias'))
//const ActionsBar = dynamic(() => import('../landing/ActionsBar'))

const host = process.env.HOST_URL

export default function TopAppBar(props) {

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('md'));
  const state = useSelector(({ origenes }) => origenes.origenes)


  const [cookies] = useCookies()
  const [log, setLog] = useState(false)
  const [userMenu, setUserMenu] = useState(null)
  const [pasosMenu, setPasosMenu] = useState(null)


  const userMenuClick = event => {
    setUserMenu(event.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  const pasosMenuClick = event => {
    setPasosMenu(event.currentTarget)
  }

  const pasosMenuClose = () => {
    setPasosMenu(null)
  }

  const dispatch = useDispatch();

  const [opaque, setOpaque] = useState(false);

  const handleScroll = () => {
    const isOpaque = window.scrollY > 0;
    setOpaque(isOpaque);
  };

  useEffect(() => {
    setLog(cookies.pltk ? true : false)
  }, [cookies])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <AppBar
        position='sticky'
        color='primary'
        style={{
          //background: opaque ? 'rgba(33, 33, 33, 0.8)' : 'transparent',
          //transition: 'background-color 0.3s',

        }}
      //className={opaque ? classes.appBarOpaque : classes.appBar}
      //elevation={0}
      >

        <Toolbar className='pl-0 pr-0'>
          <Box className="flex items-center w-full">

            <Box className='mr-4'

            >
              <Box className='flex'>

                <Link
                  href={'/'}
                  aria-label="compara-precios.com.ar"
                  className='block text-white flex items-center'
                  onClick={() => {
                    dispatch(Actions.updateCompara({ id: 'obteniendo', value: false }));
                    dispatch(Actions.updateCompara({ id: 'urlstream', value: '' }));
                    dispatch(Actions.updateCompara({ id: 'buscando', value: null }));
                  }}
                >
                  <span
                    className='ml-2'
                    onClick={() => {
                      //gtag.event({ action: 'click', category: 'ui', label: 'topbar', value: 'logo' })
                    }}
                  >

                    <IconFull />

                  </span>
                  <Box
                    className="ml-2"
                    sx={{
                      display: { xs: 'none', md: 'block' },
                    }}
                  >
                    Compara Precios
                  </Box>

                </Link>


              </Box>
            </Box>

            {!match ? (

              <Box className='flex-1'>

                <Grid container>

                  <Grid item xs={3}>
                    <Categorias {...props} />
                  </Grid>


                  <Grid item xs={9}>
                    <Buscador  {...props} />

                  </Grid>

                </Grid>

              </Box>
            ) : (
              <Box className='flex-1'>

                <Grid container>

                  <Grid item xs={state && state.escribiendo === true ? 0 : 3}>
                    <Categorias {...props} />
                  </Grid>


                  <Grid item xs={state && state.escribiendo === true ? 12 : 9}>
                    <Buscador  {...props} />
                  </Grid>

                </Grid>

              </Box>

            )}


            {/*
            <Box
              className="ml-4 mr-2"
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >

              <Box
                className='text-white'
                onClick={userMenuClick}

              >
                <MenuIcon
                //style={{ fontSize: 16 }} 
                />
              </Box>



              <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                classes={{
                  paper: 'py-2'
                }}
              >

                {!log &&

                  <MenuItem
                    onClick={() => {
                      ////gtag.event({ action: 'click', category: 'ui', label: 'topbar', value: 'menu registro' })
                      userMenuClose()
                      window.open('/micuenta/registro', '_self')
                    }}
                  >
                    <ListItemText className='' primary='Registro' />
                  </MenuItem>
                }


                {log &&

                  <MenuItem
                    onClick={() => {
                      ////gtag.event({ action: 'click', category: 'ui', label: 'topbar', value: 'menu logout' })
                      window.open('/micuenta/logout', '_self')
                    }}
                  >
                    <ListItemText className='' primary='Salir' />
                  </MenuItem>
                }

              </Popover>

            </Box>

                */}

          </Box>
        </Toolbar>

      </AppBar>
    </Fragment>
  )

}
