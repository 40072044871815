//import React, { useState } from 'react'
//import dynamic from 'next/dynamic'
//const Gb = dynamic(() => import('./Gb'))
//const NavText = dynamic(() => import('./NavText'))
//const Structure = dynamic(() => import('./Structure'))
import Structure from './Structure'

function Landing(props) {

  //const [actions, setActions] = useState({ smallLogo: false })

  return (
    <Structure {...props} />
  )

}



export default Landing
