import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import ConfirmDialog from '../shared/ConfirmDialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Categorias = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(({ origenes }) => origenes.origenes);

    const [openAlert, setOpenAlert] = useState(false);
  

    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down('md'));

    const selectedValue = state?.categoria || '';

    // Opciones del Select
    const menuItems = (state?.categorias || []).map((item, index) => (
        <MenuItem key={index} value={item.url}>
            {item.title}
        </MenuItem>
    ));

    if (match && state && state.escribiendo === true) {
        return null;
    }

    return (
        <React.Fragment>
            <Paper className="p-3 pr-2 mr-0 cursor-pointer" elevation={0} square
                onClick={() => setOpenAlert(true)}
            >
                {state?.categorias && (
                    <Select
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                            dispatch(Actions.updateCompara({ id: 'categoria', value: e.target.value }));
                        }}
                        //disabled={state?.obteniendo}
                        disabled={true}
                        size='small'
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#7b1fa2',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#4a148c',
                            },
                        }}
                    >
                        {menuItems.length ? menuItems : <MenuItem value="">No hay categorías disponibles</MenuItem>}
                    </Select>
                )}
            </Paper>

            <ConfirmDialog
                open={openAlert}
                title={' '}
                text={'Actualmente el resto de categorías no se encuentran disponibles. Disculpe las molestias.'}
                showConfirm={true}
                textButtonConfirm={'Ok'}
                showCancel={false}
                textButtonCancel={'Cancelar'}
                iconName={'info'}
                onConfirm={() => setOpenAlert(false)}
                onCancel={() => setOpenAlert(false)}
                showIcon
                iconSize={60}
                textClassname={"mt-4 text-lg text-center leading-normal"}
            />
        </React.Fragment>
    );
};

export default Categorias;
