import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
//import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
//import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

export default function ConfirmDialog(props) {

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


	return (
		<div>
			<Dialog
				open={props.open}
				onClose={() => props.onCancel(true)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'sm'}
				fullWidth
				fullScreen={fullScreen}
			>
				{props.title && props.title !== '' &&
					<DialogTitle id='alert-dialog-title'>{props.title ? props.title : 'Alerta'}</DialogTitle>
				}
				<DialogContent>

					{props.showIcon && props.iconName !== 'info' &&
						<div className={props.iconClassname ? props.iconClassname : 'text-center mb-2'}>
							<Icon
								color={props.iconColor ? props.iconColor : 'warning'}
								//fontSize={props.iconSize?props.iconSize:'large'}
								style={{ fontSize: props.iconSize ? props.iconSize : 50 }}
							>
								{props.iconName ? props.iconName : 'warning'}
							</Icon>
						</div>
					}

					{props.showIcon && props.iconName === 'info' &&
						<div className={props.iconClassname ? props.iconClassname : 'text-center mb-2'}>
							<InfoIcon color={props.iconColor ? props.iconColor : 'warning'} style={{ fontSize: props.iconSize ? props.iconSize : 50 }} />
						</div>
					}

					{
						!props.children ?
							(
								<div className={props.textClassname ? props.textClassname + ' opacity-75' : ' opacity-75'} >
									< p dangerouslySetInnerHTML={{ __html: props.text }} />
								</div>
							)
							:
							(
								<div className={props.textClassname ? props.textClassname : ''}>{props.children}</div>
							)
					}



				</DialogContent>
				<DialogActions>
					{props.showCancel === true && (
						<Button onClick={() => props.onCancel(true)} color='error'>
							{props.textButtonCancel}
						</Button>
					)}
					{props.showConfirm === true && (
						<Button
							onClick={() => props.onConfirm(true)}
							color='success'
							disabled={props.disabled}
						>
							{props.textButtonConfirm}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div >
	)
}
