import React, { useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import { Divider, InputAdornment, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useTheme } from '@mui/material/styles';
import BackspaceIcon from '@mui/icons-material/Backspace';
//recomendaciones
/**
 para que las busquedas sean mas eficientes y puedas vcomaprar mejro lso productos trata de qeu si no es necesaario utilizar por jemplo 
 Salamin Picado Grueso Las Dinas en ve de Salamin Picado Grueso Las Dinas X 1kg
 */




const Buscador = (props) => {
    const theme = useTheme();
    const inputSearch = useRef(null); // Referencia al TextField
    const router = useRouter();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.origenes.origenes);
    const [terminoBusca, setTerminoBusca] = useState('');

    const [errorTermino, setErrorTermino] = useState(false)

    const searchButtonRef = useRef(null);


    /*
    const enviar = () => {
        if (terminoBusca.trim()) {
            router.push(`/${state.categoria}/${terminoBusca}`);
        }
    };
    */

    const enviar = () => {
        setErrorTermino(false)
        const terminoValido = (termino) => {
            const terminoTrimmed = termino.trim().toLowerCase();
            // Condición para permitir solo "te" o "té", o términos con más de 2 caracteres
            const esValidoPorLongitud = terminoTrimmed === "te" || terminoTrimmed === "té" || terminoTrimmed.length > 2;

            // Expresión regular para permitir solo letras y números (puedes ajustar según lo que desees)
            const caracteresPermitidos = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]*$/;

            // Verificar longitud y caracteres permitidos
            return esValidoPorLongitud && caracteresPermitidos.test(terminoTrimmed);
        };

        if (terminoBusca.trim() && terminoValido(terminoBusca)) {
            router.push(`/${state.categoria}/${terminoBusca.trim()}`);
        } else {
            console.log("El término de búsqueda no es válido.");
            setErrorTermino(true)
            // Aquí puedes agregar un mensaje de error o manejar la validación en el UI.
        }
    };

    const handleFocus = () => {
        dispatch(Actions.updateCompara({ id: 'escribiendo', value: true }));
    };

    const handleBlur = () => {
        dispatch(Actions.updateCompara({ id: 'escribiendo', value: false }));
    };

    const handleTouchEnd = () => {
        setTimeout(() => {
            if (document.activeElement.tagName !== 'INPUT') {
                dispatch(Actions.updateCompara({ id: 'escribiendo', value: false }));
            }
        }, 100);
    };

    
    useEffect(() => {
        if (props.termino && props.termino !== '') {
            setTerminoBusca(props.termino);
        }
    }, [props.termino]);
    

    return (
        <Paper className="p-3" elevation={0} square>
            <Box className="flex items-center">
                <TextField
                    inputRef={inputSearch}  // Asignamos la referencia correctamente
                    className="flex-1"
                    fullWidth
                    placeholder="Por ejemplo, coca cola, papel higiénico..."
                    value={terminoBusca}
                    onChange={(e) => setTerminoBusca(e.target.value)}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#9c27b0',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#7b1fa2',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#4a148c',
                        },
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            enviar(); // Enviar búsqueda con Enter
                        } else if (e.key === 'Tab') {
                            e.preventDefault(); // Prevenir comportamiento predeterminado del Tab
                            searchButtonRef.current.focus(); // Foco en el botón de búsqueda
                        }
                    }}
                    disabled={state.obteniendo}
                    InputProps={{
                        endAdornment: terminoBusca.length > 0 && (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={state.obteniendo}
                                    sx={state.obteniendo ? {} : { borderColor: '#9c27b0', color: '#9c27b0' }}
                                    aria-label="limpiar búsqueda"
                                    onClick={() => {
                                        setTerminoBusca('');
                                        setTimeout(() => inputSearch.current.focus(), 100); // Foco en el input tras limpiar
                                    }}
                                >
                                    <BackspaceIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onTouchEnd={handleTouchEnd}
                    error={errorTermino}
                />
                <IconButton
                    size="small"
                    className="ml-2"
                    sx={state.obteniendo ? {} : { borderColor: '#9c27b0', color: '#9c27b0' }}
                    onClick={enviar}
                    disabled={state.obteniendo}
                    ref={searchButtonRef} // Asignamos la referencia al botón de búsqueda

                >
                    <SearchIcon />
                </IconButton>


                {state && state.resultados && state.resultados.length > 0 &&
                    <Box className="flex">

                        <Divider className="ml-2 mr-2" orientation="vertical" flexItem />

                        <Box className="flex flex-col items-center justify-center ml-2 mr-2">
                            <Box>{state.origenes} or.</Box>
                        </Box>
                        <Box className="flex flex-col items-center justify-center ml-2 mr-2">
                            <Box>{state.totales} res.</Box>
                        </Box>
                        <Box className="flex flex-col items-center justify-center ml-2 mr-2" >
                            <LocationOnIcon />
                        </Box>

                        <Divider className="ml-2 mr-2" orientation="vertical" flexItem />
                        {!state.obteniendo ? (
                            <Tooltip title={"Ordenar precio " + (state.priceSortMejores === 'asc' ? 'desc.' : 'asc.' || 'asc')}>
                                <IconButton
                                    variant="outlined"
                                    size="small"
                                    className="ml-2"
                                    onClick={() => { dispatch(Actions.resultadosOrden()) }}
                                >
                                    <SwapVertIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton
                                variant="outlined"
                                size="small"
                                className="ml-2"
                                disabled={true}
                            >
                                <SwapVertIcon />
                            </IconButton>
                        )}
                    </Box>
                }
            </Box>
        </Paper>
    );
};

export default Buscador;



{/*
                    <Button
                        variant='outlined'
                        size='small'
                        className="ml-2"
                        onClick={() => { dispatch(Actions.resultadosOrden()) }}
                        //disabled={state.origenes > 0 && state.obteniendo === true ? false : true}
                        disabled={state.obteniendo}
                    >
                        ordenar precio {state.priceSortMejores === 'asc' ? 'desc' : 'asc' || 'asc'}
                    </Button>
                )}



                {/*
                <Box
                    sx={{
                        display: { xs: 'none', md: 'block' },
                    }}
                    className="flex"
                >


                    <Divider className="ml-2 mr-2" orientation="vertical" flexItem />
                    <Box className="flex flex-col items-center justify-center ml-2 mr-2">
                        <Box>{state.origenes} or.</Box>
                    </Box>
                    <Box className="flex flex-col items-center justify-center ml-2 mr-2">
                        <Box>{state.totales} res.</Box>
                    </Box>
                    <Box className="flex flex-col items-center justify-center ml-2 mr-2" >
                        <LocationOnIcon />
                    </Box>


                </Box>

                */}