//import React, { useRef, useState } from 'react';
import dynamic from 'next/dynamic'
import { Container, Paper, Box, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Resultados from './Resultados'
import PublicidadColumna from './AdsColumna'
//const Resultados = dynamic(() => import('./Resultados'))
//const PublicidadColumna = dynamic(() => import('./AdsColumna'))


//const ActionsBar = dynamic(() => import('./ActionsBar'))

const Structure = (props) => {

    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const state = useSelector(({ origenes }) => origenes.origenes);
    return (
        <Box className="bg-yellow-300- h-full flex-1">
            <Grid container spacing={0} className="h-full">

                <Grid item xs={12} md={match === false&& (state.publicidad && state.publicidad === true) ? 8 : 12}>

                    <Box className="bg-green-400- h-full flex flex-col pt-2-">
                        {/*
                         
                        <Box>

                            <ActionsBar />

                        </Box>
                        */}
                        <Box className="flex-1">

                            <Resultados {...props}/>

                        </Box>
                    </Box>

                </Grid>

                {match === false && state.publicidad === true&&
                    <Grid item md={4}>
                        <PublicidadColumna />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default Structure