import React, { useEffect, useRef, useState } from 'react';

const AdSense = ({ adClient, adSlot, adFormat = "auto", fullWidthResponsive = true, fixedHeight }) => {
  const adRef = useRef(null);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  useEffect(() => {
    // Verifica si el entorno es de producción
    if (process.env.NODE_ENV !== 'production') {
      console.log('AdSense no se carga en entornos de desarrollo.');
      return;
    }

    const loadAd = () => {
      if (!isAdLoaded && adRef.current && adRef.current.offsetWidth > 0) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        setIsAdLoaded(true);
      }
    };

    // Cargar el script de AdSense si aún no está cargado
    const script = document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`;
    script.async = true;
    script.setAttribute('data-ad-client', adClient);
    document.head.appendChild(script);

    // Intentar cargar el anuncio después de renderizar
    loadAd();

    // Escuchar cambios de tamaño de ventana por si el contenedor cambia de tamaño
    window.addEventListener('resize', loadAd);

    return () => {
      window.removeEventListener('resize', loadAd);
    };
  }, [adClient, isAdLoaded]);

  return (
    <div 
      ref={adRef} 
      style={{ 
        textAlign: 'center', 
        height: fixedHeight || '250px', // Altura fija pasada como parámetro, con un valor predeterminado de 250px
        minWidth: '300px', 
        maxWidth: '100%',
        overflow: 'hidden' 
      }}>
      <ins className="adsbygoogle"
           style={{ display: 'block', height: '100%' }}
           data-ad-client={adClient}
           data-ad-slot={adSlot}
           data-ad-format={adFormat}
           data-full-width-responsive={fullWidthResponsive ? 'true' : 'false'}></ins>
    </div>
  );
};

export default AdSense;
