import React from 'react'
//import Icon from '@mdi/react'
//import {mdiBlur } from '@mdi/js'
//import AssistantIcon from '@mui/icons-material/Assistant';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
const IconFull = () => {
	return (
		<React.Fragment>
			<div className='relative' style={{ width: 30, height: 30 }}>
				<LocalOfferIcon className='absolute' style={{ color: '#9c27b0', fontSize: 30, left: 0 }} />
				<FilterTiltShiftIcon className='absolute' style={{ color: '#121212', fontSize: 15, left: '50%', marginLeft: -7, top: '50%', marginTop: -7 }} />
			</div>
		</React.Fragment>
	)
}

export default IconFull
