import React from 'react'
import { Box, Grid } from '@mui/material'

const Valor = (props) => {

    const d = props.decimal ? props.decimal : 2

    function convert(v) {
        //console.log(v)
        v = v ? v.toString() : ''
        let numero = v.indexOf('.') ? v.split('.') : v
        let entero = Array.isArray(numero) ? numero[0] : numero
        let decimal = Array.isArray(numero) && numero[1] ? numero[1] : 0


        if (decimal.length > d) {
            decimal = decimal.substr(0, d)
        }

        if (decimal.length === 1) {
            decimal = decimal + '0'
        }

        //completa
        if (d && d > decimal.length) {
            for (let index = 0; index < d - decimal.length; index++) {
                decimal += '0'
            }
            // decimal = decimal + '0'
        }

        entero = entero.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

        return entero + ',' + decimal
    }

    if (props.v === '') {
        return null
    }


    return (
        <Box
        {...props}
        >
            $ {convert(parseFloat(props.v.replace(/[^\d,]/g, '').replace(',', '.')))}
        </Box>

    )


    /*
    return (
        <div>
            <Grid container
                //spacing={2}
                className={props.className ? props.className : ''}
            >
                {/*
                <Grid item xs={2} className='pr-8- text-right'>$</Grid>
                *
                <Grid item xs={props.moneda ? 9 : 12}>
                    <div className='pr-8 bg-red-400-' style={{ textAlign: 'right' }}>
                        {props.t && props.t === 'egreso' ? (
                            <span className='text-red-400' >- {convert(props.v)}</span>
                        ) : (
                            <span> {convert(props.v)}</span>
                        )
                        }
                    </div>
                </Grid>
                {props.moneda &&
                    <Grid item xs={3} className='pr-8'>{props.moneda}</Grid>
                }

            </Grid>
        </div>
    )
    */
}

export default Valor