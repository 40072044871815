import React from 'react'
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Box } from '@mui/material';
const IconCp = () => {
    return (
        <Box>
            <Box className='relative' sx={{ width: 20, height: 20}}>
                <LocalOfferIcon className='absolute' sx={{ color: '#fff', fontSize: 20, left: 0 }} />
                <FilterTiltShiftIcon className='absolute' sx={{ color: '#121212', fontSize: 10, left: '50%', marginLeft: -0.5, top: '50%', marginTop: -0.5 }} />
            </Box>
        </Box>
    )
}

export default IconCp
