import React, { useEffect, useState, useRef, useContext } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
//import Chip from '@mui/material/Chip'
import { Typography } from '@mui/material'
//import Etiquetas from './Etiquetas'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
// import required modules
import {
    //Navigation, Pagination, Autoplay, A11y, EffectFade, Virtual,
    FreeMode, Scrollbar, Mousewheel
} from "swiper";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import { styled } from '@mui/system';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));



const TerminosBuscados = (props) => {
    //console.log(props)
    //const dispatch = useDispatch()

    const state = useSelector(({ origenes }) => origenes.origenes)
  
    return (
        <Box className="p-1 h-full">
            {state && state.terminos &&
                <Paper className='p-2 pl-0'>
                    <Swiper
                        className="mySwiper"
                        style={{
                            height: 'calc(100vh - 90px)',
                        }}
                        direction={'vertical'}
                        slidesPerView={'auto'}
                        freeMode={true}
                        scrollbar={true}
                        mousewheel={true}
                        modules={[FreeMode, Scrollbar, Mousewheel]}
                    >



                        <SwiperSlide
                            style={{ height: 'auto' }}
                            className='p-2 h-full'
                        >
                            <Typography variant='h6' className='mb-4 ml-2 mt-2'>Términos más buscados:</Typography>

                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                {state && state.terminos.map((item, index) => (
                                    <Item key={index} >
                                        <Link href={'/supermercado/' + item.termino}> <Typography>{item.termino}</Typography></Link>
                                    </Item>
                                ))}
                            </Stack>
                        </SwiperSlide>
                    </Swiper>
                </Paper>
            }
        </Box>

    )
}


export default TerminosBuscados