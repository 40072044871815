//import React, { useRef, useState } from 'react';
import dynamic from 'next/dynamic'
import { Container, Paper, Box, Skeleton } from '@mui/material';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation, Pagination, EffectCube, Autoplay, A11y, EffectFade, FreeMode, Scrollbar, Mousewheel } from "swiper";

import AdSense from './AdSense';

const Resultados = (props) => {


    return (
        <Box className="p-1 h-full">
            <Paper className='p-4'>
                <Swiper
                    /*
                    effect={'cube'}
                    grabCursor={true}
                    cubeEffect={{
                        shadow: true,
                        slideShadows: true,
                        shadowOffset: 20,
                        shadowScale: 0.94,
                    }}
                    pagination={true}
                    */
                    //modules={[EffectCube, Pagination]}
                    className="mySwiper"
                    style={{
                        height: 'calc(100vh - 105px)',
                    }}
                    direction={'vertical'}
                    slidesPerView={'auto'}
                    freeMode={true}
                    scrollbar={true}
                    mousewheel={true}
                    modules={[
                        FreeMode, Scrollbar,
                        Mousewheel]}
                >



                    <SwiperSlide
                        style={{ height: 'auto' }}
                    >
                        <Box style={{ width: '100%', maxHeight: '100%' }}
                            className='mb-2 border border-slate-800 rounded-lg flex justify-center items-center'>
                            <AdSense
                                adClient="ca-pub-7542824617421637"  // Reemplaza con tu ID de cliente
                                adSlot="2532170973"      // Reemplaza con tu ID de slot de anuncio
                                fixedHeight="300px"
                            />
                        </Box>
                        <Box className="hidden">
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                <Box key={index}
                                    style={{ width: '100%', height: 126 }}
                                    className='mb-2 border border-slate-800 rounded-lg flex justify-center items-center'>
                                    ADS
                                </Box>
                            ))}
                        </Box>

                    </SwiperSlide>

                </Swiper>
            </Paper>

            {/*
            <Paper className="p-2 max-h-full overflow-auto">
                {[0, 1, 2, 3,4,5,6,7,8,9].map((item, index) => (
                    <Skeleton variant="rectangular" key={index} width={'100%'} height={126} className='mb-1' />
                ))}
            </Paper>
    */}

        </Box>
    )
}

export default Resultados